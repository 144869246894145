import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  AppBar,
  Box,
  Stack,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  IconButton,
  Badge,
} from '@mui/material';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as LinkIcon } from '../../../assets/LinkIcon.svg';
import { ReactComponent as InIcon } from '../../../assets/InIcon.svg';
import { ReactComponent as OutIcon } from '../../../assets/OutIcon.svg';
import { ReactComponent as Business } from '../../../assets/Business.svg';
import { ReactComponent as SearchIcon } from '../../../assets/Search.svg';
import { ReactComponent as FavoriteIcon } from '../../../assets/FavoriteIcon.svg';
import { ReactComponent as Cart } from '../../../assets/Bag.svg';
import AccountPopover from './AccountPopover';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { extractNumbersFromUrl } from '../../../utils/utils';
import toast from '../../../utils/snackBarUtils';
import { setDataTestId } from '../../../utils/setDataTestId';
import { DATA_IDS } from '../../../utils/cypress';
import { useSelector } from 'react-redux';
import MainLogo from '../../../assets/MainLogo.png';

const RootStyle = styled(AppBar)(() => ({
  boxShadow: 'none',
  background: '#fff',
  display: 'flex',
  justifyContent: 'center',
  padding: '0 12px',
}));

const StyledToolbar = styled(Toolbar)`
  height: 84px;
  background: #fff;
  border-radius: 0 0 16px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  box-shadow:
    0 0 2px 0 rgba(145, 158, 171, 0.24),
    0 16px 32px -4px rgba(145, 158, 171, 0.24);
`;

const StyledRouterLink = styled(RouterLink)`
  width: 116px;
  height: 34px;
`;

const NavigationContainer = styled(Box)`
  display: flex;
`;

const NavItem = styled(Box)`
  display: flex;
  margin-right: 10px;
  align-items: center;
  cursor: pointer;
`;

const SearchWrap = styled(Box)`
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  border: 1px solid rgba(9, 36, 75, 0.2);
  cursor: pointer;
  margin-right: 12px;
`;

const SearchBar = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  border: 1px solid #09244ba2;
  width: 300px;
  height: 52px;
  margin-right: 12px;
`;

export const navigations = [
  {
    title: 'Инструменты',
    children: [
      {
        title: 'Внутренняя аналитика',
        icon: <InIcon />,
        children: [
          {
            title: 'Мой магазин',
            path: PATH_DASHBOARD.general.shop,
          },
          {
            title: 'Финансовый Отчет',
            path: PATH_DASHBOARD.general.financial_report,
          },
        ],
      },
      {
        title: 'Внешняя аналитика',
        icon: <OutIcon />,
        children: [
          {
            title: 'Черный ящик',
            path: PATH_DASHBOARD.general.analytics,
          },
          {
            title: 'Аналитика категорий',
            path: PATH_DASHBOARD.general.category_analytics,
          },
          {
            title: 'Кубик Рубика',
            path: PATH_DASHBOARD.general.unit_economy,
          },
        ],
      },
      {
        title: 'Бизнес инструменты',
        icon: <Business />,
        children: [
          {
            title: 'Портал специалистов',
            path: PATH_DASHBOARD.user.cards,
          },
          {
            title: 'Юнит экономика',
            path: PATH_DASHBOARD.general.profit_calculator,
          },
          {
            title: 'Баркодер',
            path: PATH_DASHBOARD.general.generate_barcode,
          },
          {
            title: 'Реклама',
            path: PATH_DASHBOARD.general.ad,
          },
        ],
      },
    ],
  },
  {
    title: 'Продвижение',
    children: [
      {
        title: 'Ключевые слова',
        path: PATH_DASHBOARD.general.semantic_core,
      },
      {
        title: 'Ant GPT',
        path: `${PATH_DASHBOARD.general.description_generator}/false`,
      },
    ],
  },
  {
    title: 'Обучение',
    children: [
      {
        title: 'Видео-инструкция',
        path: PATH_DASHBOARD.general.video_instructor,
      },
      {
        title: 'Медиапортал',
        path: PATH_DASHBOARD.general.media_portal,
      },
      {
        title: 'Видео-обучение',
        path: PATH_DASHBOARD.general.video_teaching,
      },
      {
        title: 'Форум',
        path: PATH_DASHBOARD.general.forum,
      },
    ],
  },
  {
    title: 'Настройки',
    children: [
      {
        title: 'Тарифы',
        path: PATH_DASHBOARD.user.billing,
      },
      {
        title: 'Промокоды',
        path: PATH_DASHBOARD.general.promocode,
      },
      {
        title: 'Настройки',
        path: PATH_DASHBOARD.user.account,
      },
    ],
  },
];

const {
  navbar: { search_by_sku },
} = DATA_IDS.dashboard;

export default function DashboardNavbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(null);
  const [inputData, setInputData] = useState();
  const currentPath = window.location.pathname;

  const { favorites } = useSelector((state) => state.catalog_favorites);
  const { totalQuantity } = useSelector((state) => state.cart);

  const navigate = useNavigate();
  const location = useLocation();

  const showCart =
    location.pathname.includes('catalog') ||
    location.pathname.includes('/dashboard/checkout') ||
    location.pathname.includes('/dashboard/create_order') ||
    location.pathname.includes('/dashboard/my_products') ||
    location.pathname.includes('/dashboard/balance');

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const productId = extractNumbersFromUrl(inputData) || null;
      if (productId && productId.length) {
        const newURL = PATH_DASHBOARD.general.analytics + `/${productId}`;
        window.location.href = newURL;
      } else {
        toast.warning('Напишите SKU или ссылку продукта');
      }
    }
  };

  const handleMenuOpen = (event, menu) => {
    setAnchorEl(event.currentTarget);
    setCurrentMenu(menu);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentMenu(null);
  };

  const handleSearchIconClick = () => {
    setShowSearch(!showSearch);
  };

  const renderMenuItems = (items) =>
    items.map((item) => (
      <>
        {item.children ? (
          <MenuItem
            key={item.title}
            sx={{
              '&:hover': {
                background: 'none',
                cursor: 'auto',
              },
            }}
          >
            <Box mr="5px" display="flex" alignItems="center">
              {item.icon}
            </Box>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#09244B',
              }}
            >
              {item.title}
            </Typography>
          </MenuItem>
        ) : (
          <MenuItem
            key={item.title}
            component={RouterLink}
            to={item.path}
            onClick={handleMenuClose}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#09244B',
              }}
            >
              {item.title}
            </Typography>
          </MenuItem>
        )}

        {item.children &&
          item.children.map((child) => (
            <MenuItem
              key={child.title}
              component={RouterLink}
              to={child.path}
              onClick={handleMenuClose}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: '400',
                  color: '#09244B',
                  ml: '25px',
                }}
              >
                {child.title}
              </Typography>
            </MenuItem>
          ))}
      </>
    ));

  return (
    <RootStyle>
      <StyledToolbar>
        <StyledRouterLink to={PATH_DASHBOARD.root}>
          <Box
            ml="30px"
            component="img"
            alt="isistant-logo"
            src={`${MainLogo}`}
          />
        </StyledRouterLink>

        <NavigationContainer>
          <NavItem
            onClick={() => navigate(PATH_DASHBOARD.general.catalog)}
            sx={{
              mr: '35px',
              borderBottom: '2px solid white',
              // background:
              //   currentPath === PATH_DASHBOARD.general.catalog && '#b0b5fa',

              '&:hover': {
                borderBottom: '2px solid #09244B',
              },
            }}
          >
            <Box mr="5px" display="flex" alignItems="center">
              <LinkIcon />
            </Box>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '700',
                color: '#09244B',
              }}
            >
              Каталог
            </Typography>
          </NavItem>
          <NavItem
            onClick={() =>
              window.open(
                'https://chrome.google.com/webstore/detail/isistant/ccnjccoemjdajbmaompkjgmoidicnkpb?hl=ru',
                '_blank',
              )
            }
            sx={{
              mr: '35px',
              borderBottom: '2px solid white',

              '&:hover': {
                borderBottom: '2px solid #09244B',
              },
            }}
          >
            <Box mr="5px" display="flex" alignItems="center">
              <LinkIcon />
            </Box>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '700',
                color: '#09244B',
              }}
            >
              Расширение
            </Typography>
          </NavItem>

          {navigations.map((item) => (
            <NavItem
              key={item.title}
              onClick={(e) => handleMenuOpen(e, item)}
              sx={{
                mr: '35px',
                borderBottom: '2px solid white',

                '&:hover': {
                  borderBottom: '2px solid #09244B',
                },
              }}
            >
              {item.icon && (
                <Box mr="5px" display="flex" alignItems="center">
                  {item.icon}
                </Box>
              )}
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: '700',
                  color: '#09244B',
                }}
              >
                {item.title}
              </Typography>
              {item.children && (
                <ArrowDropDownIcon style={{ color: '#09244B' }} />
              )}
            </NavItem>
          ))}
        </NavigationContainer>
        <Stack direction="row">
          {!showSearch ? (
            <SearchWrap onClick={handleSearchIconClick}>
              <SearchIcon style={{ color: '#09244B' }} />
            </SearchWrap>
          ) : (
            <SearchBar>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                inputProps={{ 'aria-label': 'search' }}
                type={'text'}
                onChange={(e) => setInputData(e.target.value)}
                placeholder="Поиск по SKU или ссылка"
                fullWidth
                onKeyDown={handleKeyDown}
                {...setDataTestId({ name: search_by_sku })}
              />
              <IconButton
                type="button"
                sx={{ p: '10px' }}
                aria-label="search"
                onClick={handleSearchIconClick}
              >
                <SearchIcon />
              </IconButton>
            </SearchBar>
          )}
          {showCart && (
            <>
              <SearchWrap
                component={RouterLink}
                to={PATH_DASHBOARD.general.catalog_favorites}
              >
                <Badge
                  showZero
                  badgeContent={
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                      }}
                    >
                      {favorites?.length || 0}
                    </Typography>
                  }
                  sx={{
                    p: '10px',
                    span: {
                      width: '28px',
                      height: '28px',
                      background: '#EC407A',
                      color: '#fff',
                      borderRadius: '50%',
                    },
                  }}
                  max={99}
                >
                  <FavoriteIcon />
                </Badge>
              </SearchWrap>
              <SearchWrap
                component={RouterLink}
                to={PATH_DASHBOARD.general.checkout}
              >
                <Badge
                  showZero
                  badgeContent={totalQuantity}
                  max={99}
                  sx={{
                    p: '10px',
                    span: {
                      width: '28px',
                      height: '28px',
                      background: '#EC407A',
                      color: '#fff',
                      borderRadius: '50%',
                    },
                  }}
                >
                  <Cart />
                </Badge>
              </SearchWrap>
            </>
          )}
          <AccountPopover />
        </Stack>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{
            mt: 1,

            ul: {
              borderRadius: '10px',
              boxShadow: ' 0px 0px 3px 0px rgba(255, 255, 255, 0.10) inset',
            },
          }}
        >
          {currentMenu?.children && renderMenuItems(currentMenu.children)}
        </Menu>
      </StyledToolbar>
    </RootStyle>
  );
}
